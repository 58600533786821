import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select';
import Swiper from 'react-id-swiper';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Formik } from 'formik';
import QRCode from 'qrcode.react';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

import Highcharts from 'highcharts';
import {
    HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Tooltip, Title, Subtitle, Legend, LineSeries, SplineSeries
} from 'react-jsx-highcharts';

const plotOptions = {
    series: {
        pointStart: 2010
    }
};


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});

// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

class Topics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text:"",
            selectedOption: null,
            items: getItems(3)
        };
        this.handleChange = this.handleChange.bind(this)

        setTimeout(()=>{
            Actions.emit("notification",{message:"Hello from topics"});
        },1000);

    }
    handleChange(value) {
        this.setState({ text: value })
        console.log(value);
    }
    handleChangeSelect = selectedOption => {
        this.setState({ selectedOption });
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }
    render(){
        return (
            <div>
                <h2>Topics</h2>
                <div>
                    <Link to="/topics/blbb">111</Link>
                    <Link to="/topics/2">222</Link>
                    <Link to="/topics/3">333</Link>
                    <Link to="/topics/4">444</Link>
                </div>

                <FormattedMessage
                    id="app.hello" values={{ name: 'Yusuf' }}
                />

                <FontAwesomeIcon data-tip="this is coffee" icon={faCoffee} />

                <div className={"row"}>
                    <div className={"columns small-12"}>
                        {(Functions.isMobile()?"mobile active":"desktop active")}
                        <QRCode value="http://facebook.github.io/react/" />

                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validate={values => {
                                let errors = {};
                                if (!values.email) {
                                    errors.email = 'Required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    alert(JSON.stringify(values, null, 2));
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  /* and other goodies */
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    {errors.email && touched.email && errors.email}
                                    <input
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                    />
                                    {errors.password && touched.password && errors.password}
                                    <button type="submit" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </form>
                            )}
                        </Formik>
                        <FormattedDate
                            value={new Date()}
                            year="numeric"
                            month="long"
                            day="2-digit"
                        />
                         -
                        <FormattedTime value={new Date()} format={"24hour"}/> - <FormattedRelativeTime value={((new Date().getTime() + (60*3)) - new Date().getTime())} numeric="auto" updateIntervalInSeconds={10} />
                        <br/>
                        <FormattedNumber
                            value={11.20}
                            style="currency"
                            currency="USD"
                        />
                        <IntlTelInput
                            preferredCountries={['tr','tw','us']}
                            onPhoneNumberChange={()=>{}}
                            onPhoneNumberBlur={()=>{}}
                            format={true}
                            autoComplete={"on"}
                        />

                        <DragDropContext onDragEnd={this.onDragEnd.bind(this)} >
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {this.state.items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {item.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Select
                            value={this.state.selectedOption}
                            onChange={this.handleChangeSelect}
                            options={[
                                { value: 'chocolate', label: 'Chocolate' },
                                { value: 'strawberry', label: 'Strawberry' },
                                { value: 'vanilla', label: 'Vanilla' },
                            ]}

                            placeholder="Search..."
                        />
                        <Swiper>
                            <div>Slide 1</div>
                            <div>Slide 2</div>
                            <div>Slide 3</div>
                            <div>Slide 4</div>
                            <div>Slide 5</div>
                        </Swiper>
                    </div>
                    <div className={"columns small-12"}>
                        <ReactQuill value={this.state.text} onChange={this.handleChange}
                                    formats={[
                                        'header',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image'
                                    ]}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': [1, 2, false] }],
                                            ['bold', 'italic', 'underline','strike', 'blockquote'],
                                            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                            ['link'],
                                            ['clean']
                                        ],
                                    }}
                        />
                    </div>
                    <div className={"columns small-12"}>
                        <HighchartsChart plotOptions={plotOptions}>
                            <Chart />
                            <XAxis>
                                <XAxis.Title>Time</XAxis.Title>
                            </XAxis>

                            <Tooltip valueSuffix=" m/s" />
                            <YAxis>
                                <YAxis.Title>Number of employees</YAxis.Title>
                                <SplineSeries name="Installation" data={[43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]} />
                                <SplineSeries name="Manufacturing" data={[24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]} />
                                <SplineSeries name="Sales & Distribution" data={[11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]} />
                                <SplineSeries name="Project Development" data={[null, null, 7988, 12169, 15112, 22452, 34400, 34227]} />
                                <SplineSeries name="Other" data={[12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]} />
                            </YAxis>
                        </HighchartsChart>
                    </div>
                </div>

                <div>
                    {this.props.pathName}
                </div>
            </div>
        );
    }
}
export default withHighcharts(Topics, Highcharts);


import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


export default class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevPath:""
        };
    }
    render(){
        return (
            <Router>
                <div>Welcome to Login Page</div>
                <div>
                    <Switch>
                        <Route path="/auth/login">
                            <div>
                                <a onClick={() =>{
                                    this.props.userAction(true);
                                }}>Login</a>
                                <Link to="/auth/register">Register</Link>
                            </div>
                        </Route>
                        <Route path="/auth/register">
                            <div>
                                Register
                                <Link to="/auth/login">Login</Link>
                            </div>
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

import React, {useCallback} from 'react'
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import Switch from 'react-ios-switch';
import ReactToPrint from 'react-to-print';
import { Calendar,DateRangePicker } from 'react-date-range';
import trLocale from 'react-date-range/dist/locale/tr';
import { BlockPicker } from 'react-color'
import AvatarEditor from 'react-avatar-editor'

import * as Space from 'react-spaces';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/kissing-emoji.json'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import ReactFilterBox, {AutoCompleteOption,SimpleResultProcessing} from "react-filter-box";
import "react-filter-box/lib/react-filter-box.css"

import * as Survey from "survey-react";
import "survey-react/survey.css";

import {useDropzone} from 'react-dropzone'
import InputMask from "react-input-mask";

import PinInput from 'react-pin-input';

import CsvDownloader from 'react-csv-downloader';

import ReactExport from "react-data-export";

var s = require('searchjs');


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];
var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 22,
        remainig: 7
    }
];

var data = [
    {
        "Name": "Aidan Benjamin",
        "Description": "nulla magna,",
        "Status": "Divorced",
        "Email": "quam.a.felis@arcu.net"
    },
    {
        "Name": "Ma Benjamin",
        "Description": "nulla magna,",
        "Status": "Divorced",
        "Email": "quam.a.felis@arcu.net"
    },
]



const dataList = [
    { id: 1, name: 'a', email: 'a@email.com', avartar: '...' },
    { id: 2, name: 'b', email: 'b@email.com', avartar: '...' },
    { id: 3, name: 'c', email: 'c@email.com', avartar: '...' }
];



const ImageCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>
        <img src={rowData[dataKey]} width="50" />
    </Cell>
);

function MyDropzone() {
    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result
            console.log(binaryStr)
        }

        acceptedFiles.forEach(file => reader.readAsArrayBuffer(file))
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
    )
}

export default class Posts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked:true,
            selection:{
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            datalist:[{name:"John",age:25},{name:"Jill",age:30},{name:"Jill",age:37}],
            isStopped: false, isPaused: false

        };

        this.options = [
            {
                columnField: "Name",
                type:"text"
            },
            {
                columnField: "Description",
                type:"text"
            },
            {
                columnField: "Status",
                type:"selection"
            },
            {
                columnText: "Email @",
                columnField: "Email",
                type:"text"
            }
        ];

        setTimeout(()=>{
            Actions.emit("notification",{message:"Hello from posts"});
        },1000);
    }
    onParseOk(expressions){
        var newData = new SimpleResultProcessing(this.options).process(data,expressions);
        this.setState({data:newData});
        console.log(newData);
    }
    handleSelect(ranges){
        this.setState({selection:ranges.selection});
    }
    //Define a callback methods on survey complete
    onComplete(survey, options) {
        //Write survey results into database
        console.log("Survey results: " + JSON.stringify(survey.data));
    }
    render(){

        var json = {
            elements: [
                { type: "text", name: "customerName", title: "What is your name?", isRequired: true},
                {
                    type: "radiogroup",
                    name: "car",
                    title: "What car are you driving?",
                    isRequired: true,
                    colCount: 4,
                    choices: [
                        "None",
                        "Ford",
                        "Vauxhall",
                        "Volkswagen",
                        "Nissan",
                        "Audi",
                        "Mercedes-Benz",
                        "BMW",
                        "Peugeot",
                        "Toyota",
                        "Citroen"
                    ]
                }
            ]
        };
        var model = new Survey.Model(json);

        const columns = [{
            id: 'first',
            displayName: 'First column'
        }, {
            id: 'second',
            displayName: 'Second column'
        }];

        const datas = [{
            first: 'foo',
            second: 'bar'
        }, {
            first: 'foobar',
            second: 'foobar'
        }];

        console.log("Filtered Data: "+JSON.stringify(s.matchArray(this.state.datalist,{age:{from:26,to:40}}))); // returns [{name:"John",age:25}]

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        const buttonStyle = {
            display: 'block',
            margin: '10px auto'
        };

        return (
            <div>
                <h2>Posts</h2>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            (num) => num + 1
                        </SyntaxHighlighter>
                    </div>
                    <div className={"columns small-12"}>
                        <Space.Fixed height={400}>
                            <Space.LeftResizable size="25%" style={{backgroundColor:'red'}}/>
                            <Space.Fill>
                                <Space.TopResizable size="25%" />
                                <Space.Fill />
                                <Space.BottomResizable size="25%" />
                            </Space.Fill>
                            <Space.RightResizable size="25%" />
                        </Space.Fixed>
                    </div>
                    <div className={"columns small-12"}>
                        <AvatarEditor
                            image="https://github.com/TPMinan/react-upload-gallery/raw/master/images/screenshot.png?raw=true"
                            width={250}
                            height={250}
                            border={50}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={1.2}
                            rotate={0}
                        />
                        <BlockPicker/>
                    </div>
                    <div className={"columns small-12"} ref={el => (this.componentRef = el)}>
                        <DateRangePicker
                            ranges={[this.state.selection]}
                            onChange={this.handleSelect.bind(this)}
                            locale={trLocale}
                        />
                    </div>
                    <div className={"columns small-12"}>
                        <div>
                            <Lottie options={defaultOptions}
                                    height={400}
                                    width={400}
                                    isStopped={this.state.isStopped}
                                    isPaused={this.state.isPaused}/>
                            <button style={buttonStyle} onClick={() => this.setState({isStopped: true})}>stop</button>
                            <button style={buttonStyle} onClick={() => this.setState({isStopped: false})}>play</button>
                            <button style={buttonStyle} onClick={() => this.setState({isPaused: !this.state.isPaused})}>pause</button>
                        </div>
                        <ReactFilterBox

                            data={this.state.list}
                            options={this.options}
                            onParseOk={this.onParseOk.bind(this)}
                        />
                    </div>
                    <div className={"columns small-12"}>
                        <MyDropzone/>
                    </div>
                    <div className={"columns small-12"}>
                        <div>
                            <ExcelFile element={<button>Download Data</button>}>
                                <ExcelSheet data={dataSet1} name="Employees">
                                    <ExcelColumn label="Name" value="name"/>
                                    <ExcelColumn label="Wallet Money" value="amount"/>
                                    <ExcelColumn label="Gender" value="sex"/>
                                    <ExcelColumn label="Marital Status"
                                                 value={(col) => col.is_married ? "Married" : "Single"}/>
                                </ExcelSheet>
                                <ExcelSheet data={dataSet2} name="Leaves">
                                    <ExcelColumn label="Name" value="name"/>
                                    <ExcelColumn label="Total Leaves" value="total"/>
                                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                                </ExcelSheet>
                            </ExcelFile>
                            <CsvDownloader
                                filename="myfile"
                                separator=";"
                                columns={columns}
                                datas={datas}
                                text="CSV DOWNLOAD" />
                            <PinInput
                                length={4}
                                initialValue=""
                                secret
                                onChange={(value, index) => {}}
                                type="numeric"
                                style={{padding: '10px'}}
                                inputStyle={{borderColor: 'red'}}
                                inputFocusStyle={{borderColor: 'blue'}}
                                onComplete={(value, index) => {}}
                            />
                        </div>
                        <div style={{margin:20}}>
                            <Survey.Survey model={model} onComplete={this.onComplete}/>
                        </div>
                    </div>
                    <div className={"columns small-12"}>
                        <InputMask mask="99/99/9999" onChange={(data)=>{console.log(data)}} />
                        <Switch
                            checked={this.state.checked}
                            className={undefined}
                            disabled={undefined}
                            handleColor="white"
                            name={undefined}
                            offColor="white"
                            onChange={(checked) => {
                                this.setState({checked:checked})
                            }}
                            onColor="rgb(76, 217, 100)"
                            pendingOffColor={undefined}
                            pendingOnColor={undefined}
                            readOnly={undefined}
                            style={undefined}
                        />
                    </div>
                    <div className={"columns small-6"}>
                        <Table
                            virtualized
                            data={dataList}
                            onRowClick={data => {
                                console.log(data);
                            }}
                        >
                            <Column width={70} align="center" fixed>
                                <HeaderCell>Id</HeaderCell>
                                <Cell dataKey="id" />
                            </Column>

                            <Column width={130}>
                                <HeaderCell>First Name</HeaderCell>
                                <Cell dataKey="firstName" />
                            </Column>

                            <Column width={130}>
                                <HeaderCell>Last Name</HeaderCell>
                                <Cell dataKey="lastName" />
                            </Column>

                            <Column width={200}>
                                <HeaderCell>City</HeaderCell>
                                <Cell dataKey="city" />
                            </Column>

                            <Column width={200}>
                                <HeaderCell>Street</HeaderCell>
                                <Cell dataKey="street" />
                            </Column>

                            <Column minWidth={200} flexGrow={1}>
                                <HeaderCell>Company Name</HeaderCell>
                                <Cell dataKey="companyName" />
                            </Column>
                        </Table>
                    </div>
                    <ReactToPrint
                        trigger={() => <a href="#">Print this out!</a>}
                        content={() => this.componentRef}
                    />
                </div>
                <div>
                    <Link to="/posts/blbb">111</Link>
                    <Link to="/posts/2">222</Link>
                    <Link to="/posts/3">333</Link>
                    <Link to="/posts/4">444</Link>
                </div>
                <div>
                    {this.props.pathName}
                </div>
            </div>
        );
    }
}


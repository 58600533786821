import Moment from 'react-moment';
import 'moment-timezone';import {isBrowser, isMobile } from "react-device-detect";

import StringUtils from "./stringUtils";
import Config from '../config';

var Functions = {
    config:Config,
    generateURI:(apiURL,obj, token) => {
        var str = [];
        for(var p in obj)
            if (obj.hasOwnProperty(p) && p != "method") {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        str.join("&");

        var data =  {
            address:'http://mobileapi_test.nuruslinks.com/'+apiURL+'/?'+str,
            header: {
                method: (obj.method != ""?obj.method:"GET"),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        }

        if(typeof token != "undefined"){
            data.header.headers["UserToken"] = token;
        }
        if(obj.method == "POST"){
            data.header.body =  JSON.stringify(obj)
        }

        return data;

    },
    getParameterByName: (name, url) => {
        if (!url) {
          url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    createFormData(params){ // {images:[],params:[]}
        var thiz = this;
        var blob = false;
        var formData = new FormData();

        params.params.forEach(function(image) {
            formData.append(image.name, image.value);
        });

        params.images.forEach(function(image) {
            blob = thiz.dataURLToBlob(image.value);
            formData.append(image.name, blob);
        });
        return formData;
    },
    dataURLToBlob(dataURL) {
        var BASE64_MARKER = 'data:image/jpeg;base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            var parts = dataURL.split(',');
            var contentType = parts[0].split(':')[1];
            var raw = parts[1];

            return new Blob([raw], {type: contentType});
        }
        else {
            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        }
    },
    prepend: (value, array) => {
      var newArray = array.slice(0);
      newArray.unshift(value);
      return newArray;
    },
    validateEmail: (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    formatPhoneNumber: (input, format) => {
      // Strip non-numeric characters
      var digits = input.replace(/\D/g, '');

      // Replace each "X" with the next digit
      var count = 0;
      return format.replace(/X/g, function() {
          return digits.charAt(count++);
      })
    },
    getBloodType(type){
      type = parseInt(type);
      var string;
      switch(type){
        case 0:
        string = "0 rh-";
        break;
        case 1:
        string = "0 rh+";
        break;
        case 2:
        string = "A rh-";
        break;
        case 3:
        string = "A rh+";
        break;
        case 4:
        string = "B rh-";
        break;
        case 5:
        string = "B rh+";
        break;
        case 6:
        string = "AB rh-";
        break;
        case 7:
        string = "AB rh+";
        break;
        default:
        string = "Seçilmemiş"
        break;
      }
      return string;
    },
    isMobile:() => {
        if(isMobile){
            return true;
        } else {
            return false;
        }
    },
    NearestCity: (obj,cities,maxDistance) => {
      if(typeof maxDistance === "undefined"){
        maxDistance = 99999999;
      }
      var Deg2Rad = (deg)=>{
          return deg * Math.PI / 180;
      };
      var PythagorasEquirectangular = (lat1, lon1, lat2, lon2)=>{
        lat1 = Deg2Rad(lat1);
        lat2 = Deg2Rad(lat2);
        lon1 = Deg2Rad(lon1);
        lon2 = Deg2Rad(lon2);
        var R = 6371; // km
        var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
        var y = (lat2 - lat1);
        var d = Math.sqrt(x * x + y * y) * R;
        return d;
      }
      var getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
        var R = 6371; // Radius of the earth in km
        var dLat = Deg2Rad(lat2-lat1);  // deg2rad below
        var dLon = Deg2Rad(lon2-lon1);
        var a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(Deg2Rad(lat1)) * Math.cos(Deg2Rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;
      }


      var latitude = obj[0];
      var longitude = obj[1];
      var mindif = 99999;
      var closest = false;

      for (var index = 0; index < cities.length; ++index) {
        var dif = PythagorasEquirectangular(latitude, longitude, cities[index][1], cities[index][2]);
        if (dif < mindif) {
          closest = index;
          mindif = dif;
        }
      }
      if(closest !== false){
        cities[closest][4] = getDistanceFromLatLonInKm(cities[closest][1],cities[closest][2],obj[0],obj[1]);
        if((cities[closest][4] * 1000) < (maxDistance)){
          return cities[closest];
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    calculateDistance: (lat1,lon1,lat2,lon2) => {
      var Deg2Rad = (deg)=>{
          return deg * Math.PI / 180;
      };
      var R = 6371; // Radius of the earth in km
      var dLat = Deg2Rad(lat2-lat1);  // deg2rad below
      var dLon = Deg2Rad(lon2-lon1);
      var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(Deg2Rad(lat1)) * Math.cos(Deg2Rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c; // Distance in km
      return d;
    },
    bearing(startLat, startLng, destLat, destLng){

        var toRadians = (degrees) =>{
            return degrees * Math.PI / 180;
        }
        var toDegrees = (radians) => {
            return radians * 180 / Math.PI;
        }

        startLat = toRadians(startLat);
        startLng = toRadians(startLng);
        destLat = toRadians(destLat);
        destLng = toRadians(destLng);

        var y = Math.sin(destLng - startLng) * Math.cos(destLat);
        var x = Math.cos(startLat) * Math.sin(destLat) -
            Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        var brng = Math.atan2(y, x);
        brng = toDegrees(brng);


        return (brng + 360) % 360;
    },
    isNumeric: (n) => {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },

    calculateDistanceByRSSI(rssi){
        var txPower = -59;  // hard coded power value. Usually ranges between -59 to -65

        if(rssi == 0) {
            return -1.0;
        }

        var ratio = rssi*1.0/txPower;
        if(ratio < 1.0) {
            return Math.pow(ratio, 10);
        }
        else {
            var distance = (0.89976)*Math.pow(ratio, 7.7095) + 0.111;
            return distance;
        }
    },

    getEpochTimeByDate(date, hour) {
        var formattedDate = Moment(date).format("YYYY-MM-DD");
        var dateHour = formattedDate + "T" + hour;
        return this.getShortEpochTime(Moment(dateHour).format("X"));
    },

    // saliseler olmadanki halini geri dön
    getShortEpochTime(epochTime){
        if(epochTime.length > 10){
            return epochTime.substring(0, 10);
        }
        return epochTime;
    },

    getCurrentItems(items) {
        let currentItems = [];
        for (let i = 0; i < items.length; i++) {
            let date = items[i].RDate;
            //console.log("date:");
            //console.log(date);
            let beginDateStr = StringUtils.replaceAtIndex(date, 11, items[i].BeginTime);
            let endDateStr = StringUtils.replaceAtIndex(date, 11, items[i].EndTime);
            //console.log("BeginDAte:  " + beginDateStr);
            //console.log("End date: " + endDateStr);
            let beginDate = Functions.getShortEpochTime(Moment(beginDateStr).format("X"));
            let endDate = Functions.getShortEpochTime(Moment(endDateStr).format("X"));
            //console.log(beginDate);
            //console.log(endDate);
            let timeArr = Functions.getShortEpochTime(Moment().format('x'));
            //console.log("cur time");
            //console.log(timeArr);
            if(beginDate < timeArr && timeArr < endDate) {
                currentItems.push(items[i]);
            }
        }
        //console.log("Valid ITems");
        //console.log(currentItems);
        return currentItems;
    }
}
export default Functions;

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Topics from "./topics";
import Posts from "./posts";
import Map from "./map";
import MapGoogle from "./map/google";

import Actions from '../helpers/actions';

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevPath:""
        };
    }
    render(){
        var firstName = Actions.getUser().data.fullName;
        firstName = (firstName == ""?" ":firstName);
        firstName = firstName.split(' ')[0];

        return (
            <div>
                <div>
                    Welcome to Main Page Mrs. {firstName}
                    (<a onClick={()=>{
                        Actions.logout();
                    }}>Logout</a>)
                </div>
                <div>
                    <Link to="/topics">Topics</Link>
                    <Link to="/posts">Posts</Link>
                </div>
                <div>
                    <Switch>
                        <Route path="/map/google" render={(props) => <MapGoogle {...props} pathName={this.props.location.pathname} /> }/>
                        <Route path="/map" render={(props) => <Map {...props} pathName={this.props.location.pathname} /> }/>
                        <Route path="/topics" render={(props) => <Topics {...props} pathName={this.props.location.pathname} /> }/>
                        <Route path="/posts" render={(props) => <Posts {...props} pathName={this.props.location.pathname} /> }/>
                    </Switch>
                </div>
            </div>
        );
    }
}

import React, {useCallback} from 'react'
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import GoogleMapReact from 'google-map-react';


export default class MapGoogle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 39.948966,
                lng: 32.661146
            },
            zoom: 11
        };
        this.directionRespond = {
            distance:{},
            duration:{}
        };
    }
    componentDidMount(){

    }
    apiIsLoaded = (map, maps) => {
        if (map) {
            this.calcRoute("39.951004,32.649623","39.877021,32.656175",map,maps);
        }
    }
    calcRoute(from,to,map,maps) {

        var icons = {
            start: new maps.MarkerImage(
                // URL
                'https://maps.google.com/mapfiles/kml/shapes/library_maps.png',
                // (width,height)
                new maps.Size( 44, 32 ),
                // The origin point (x,y)
                new maps.Point( 0, 0 ),
                // The anchor point (x,y)
                new maps.Point( 22, 32 )
            ),
            end: new maps.MarkerImage(
                // URL
                'https://maps.google.com/mapfiles/kml/shapes/schools_maps.png',
                // (width,height)
                new maps.Size( 44, 32 ),
                // The origin point (x,y)
                new maps.Point( 0, 0 ),
                // The anchor point (x,y)
                new maps.Point( 22, 32 )
            )
        };


        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer({suppressMarkers: true});

        directionsService.route({
            origin: from,
            destination: to,
            waypoints: [
                {
                    location: '39.904952, 32.647963',
                    stopover: true
                },
                {
                    location: '39.939088, 32.649548',
                    stopover: true
                },
            ],
            optimizeWaypoints: true,
            travelMode: 'DRIVING',
            drivingOptions: {
                departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                trafficModel: 'optimistic'
            }
        }, (response, status) => {

            console.log(response);

            if(true){
                this.directionRespond = {
                    distance:response.routes[0].legs[0].distance,
                    duration:response.routes[0].legs[0].duration
                }
            }

            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);


                new maps.Marker({
                    position: response.routes[0].legs[0].start_location,
                    map: map,
                    icon: icons.start,
                    title: "title"
                });

                response.routes[0].legs.forEach((leg)=>{
                    new maps.Marker({
                        position: leg.end_location,
                        map: map,
                        icon: icons.end,
                        title: "title"
                    });
                })
            } else {
                window.alert('Directions request failed due to ' + status);
            }
        });
    }
    render(){
        return (
            <div>
                <h2>Map Google</h2>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <div style={{ height:500,position:'relative' }}>
                            {JSON.stringify(this.directionRespond.distance.text)+" @ "+(this.directionRespond.distance.value / 1000)+" km"}
                            <GoogleMapReact
                                bootstrapURLKeys={{ key:Functions.config.google.map.KEY}}
                                defaultCenter={this.state.center}
                                defaultZoom={this.state.zoom}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
                            >
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


import React, {useCallback} from 'react'
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import ReactMapGL, {Marker, Popup} from 'react-map-gl';
import {fromJS} from 'immutable';
import geoJSON from '../../assets/data/geojson';
const mapStyle = fromJS({
    version: 8,
    sources: {
        "mapbox-streets": {
            "type": "vector",
            "url": "mapbox://styles/mapbox/streets-v11"
        }
    }
});


export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup:true,
            viewport: {
                width: 400,
                height: 400,
                latitude: 37.83381888486939,
                longitude: -122.48369693756104,
                zoom: 12
            }
        };
    }
    componentDidMount(){


        const map = this.reactMap.getMap();
        map.on('load', () => {
            if(true){
                map.addLayer({
                    "id": "route",
                    "type": "line",
                    "source": {
                        "type": "geojson",
                        "data": {
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "type": "LineString",
                                "coordinates": [
                                    [-122.48369693756104, 37.83381888486939],
                                    [-122.48348236083984, 37.83317489144141],
                                    [-122.48339653015138, 37.83270036637107],
                                    [-122.48356819152832, 37.832056363179625],
                                    [-122.48404026031496, 37.83114119107971],
                                    [-122.48404026031496, 37.83049717427869],
                                    [-122.48348236083984, 37.829920943955045],
                                    [-122.48356819152832, 37.82954808664175],
                                    [-122.48507022857666, 37.82944639795659],
                                    [-122.48610019683838, 37.82880236636284],
                                    [-122.48695850372314, 37.82931081282506],
                                    [-122.48700141906738, 37.83080223556934],
                                    [-122.48751640319824, 37.83168351665737],
                                    [-122.48803138732912, 37.832158048267786],
                                    [-122.48888969421387, 37.83297152392784],
                                    [-122.48987674713133, 37.83263257682617],
                                    [-122.49043464660643, 37.832937629287755],
                                    [-122.49125003814696, 37.832429207817725],
                                    [-122.49163627624512, 37.832564787218985],
                                    [-122.49223709106445, 37.83337825839438],
                                    [-122.49378204345702, 37.83368330777276]
                                ]
                            }
                        }
                    },
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "#888",
                        "line-width": 8
                    }
                });
            }
        })
    }
    render(){
        return (
            <div>
                <h2>Map</h2>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <ReactMapGL
                            mapStyle={"mapbox://styles/mapbox/streets-v11"}
                            mapboxApiAccessToken={Functions.config.mapBox.KEY}
                            ref={(reactMap) => this.reactMap = reactMap}
                            {...this.state.viewport}
                            onViewportChange={(viewport) => this.setState({viewport})}
                        >
                            <Marker latitude={37.78} longitude={-122.41} offsetLeft={-20} offsetTop={-10} >
                                <div onClick={()=>{
                                    this.setState({showPopup:true});
                                }}>You are here</div>
                            </Marker>
                            {this.state.showPopup && <Popup
                                latitude={37.78}
                                longitude={-122.41}
                                closeButton={true}
                                closeOnClick={false}
                                onClose={() => this.setState({showPopup: false})}
                                anchor="top" >
                                <div>You are here</div>
                            </Popup>}
                        </ReactMapGL>
                    </div>
                </div>
            </div>
        );
    }
}

